import React from 'react';
import { Link } from 'react-router-dom';

const Support: React.FC = () => {
  return (
    <div className="App-header">
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfJfyQPxrraxI4x8vaWqJivWxvbfKk2Ad0pl7qBYYVZtDvUag/viewform?embedded=true" width="640" height="541">
            Loading…
        </iframe>
    </div>
  );
};

export default Support;