import React, {useEffect} from 'react';
import { useParams } from 'react-router-dom';

const Invite: React.FC = () => {
    const { path } = useParams();

    return (
        <div className="App-header">
            <a href={`monogatari://invite/${path}`}>click to invite if not automatic</a>
        </div>
    );
};
  
export default Invite;