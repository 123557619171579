import React from 'react';
import { Link } from 'react-router-dom';

const DeleteAccount: React.FC = () => {
  return (
    <div className="App-header">
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeT1z_nGc5iRFpdlzZW_u0uRMqTG13esiJkhHYNZnFe8Q24Tg/viewform?embedded=true" width="640" height="472">
            Loading…
        </iframe>
    </div>
  );
};

export default DeleteAccount;